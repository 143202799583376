
/* =Tablet landscape
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 1024px) {
	.section5 {
		.testi-row { 
			flex-wrap: wrap;
			a {
				width: 45%;
			}
		}
	}
}

/* =Tablet portrait
--------------------------------------------------------------------------------------------------------*/
@media only screen and ( max-width: 767px ) {
	.desc-box1 { top: 200px; right: 50%; transform: translateX(50%); }
	.parallax-item-1, .parallax-item-2, .parallax-item-6a { transform: scale(0.8); }
	.parallax-item-1 { right: -30px; }
	.parallax-item-2 { left: -150px; }
	.parallax-item-3a { display: none !important; }
	.parallax-item-5a { display: none !important; }
	.parallax-item-6a { top: auto; bottom: 100px; left: -50px; }
	.section2 { background: $dark-color; }
	.section2 .menu-container { position: relative; display: flex; flex-direction: column; }
	.section2 .menu-indicate { display: none; }
	.section2 .menu-img { width: 100%; min-height: 50vh; overflow: hidden; }
	.section2 .menu-col { width: 100%; min-height: 50vh; padding: 0px; }
	.section2 .menu-col .middle { position: relative; top: 0; transform: none; }
	.section2 .menu-col-inner { padding: 0px; }
	.section2 .menu-list ul { overflow-x: hidden; }
	.section2 #menu-others .menu-list-row { white-space: normal; }
	.section2 .menu-img { min-height: 70vh; }
	.section2 .menu-img:after { content: ""; width: 100%; height: 1px; background: $gold-color; position: absolute; left: 0; right: 0; bottom: 0px; margin: 0px auto; }
	.section2 .menu-img-src { height: 80%; overflow: visible; background-position-y: 60%; }
	.section2 .menu-img-desc { min-height: 70px; position: relative; top: 100%; bottom: auto; padding-top: 10px; display: flex; align-items: center; }
	.section2 .menu-img-desc h5 { display: inline-block; margin-right: 15px; margin-top: 0px; }
	.section2 .menu-img-desc p { display: none; }
	.section2 {
		.back-basics h4,
		.carbonara h4,
		.ben-addict h4,
		.big-break h4,
		.egg-poached h4,
		.beef-chicken-sausages h4,
		.beef-bacon-turkey-ham h4 {
			font-size: 1.5em;
		}
		.big-break span {
			font-size: 0.6em;
		}
	}
	.section2 .menu-img-desc-vlign { width: 80%; transform: none; text-align: left; }
	.section2 .menu-img-desc-vlign p { font-size: 1.2em; text-align: center; display: block; position: absolute; top: -150%; left: 0; right: 0; margin: auto; }
	.section2 .menu-img-desc-vlign img { position: absolute; top: -330%; left: 0; right: 0; margin: 0px auto; }
	.section2 .menu-img-desc-vlign h5 img { position: relative; top: auto; }
	.section2 .arrow { left: -40px; }
	.section2 .arrow-left { top: 60%; }
	.section2 .arrow-right { top: 70%; }
	.lean-and-green,
	.butter-chick,
	.its-all-abutter-bass,
	.youre-finstastic,
	.back-basics,
	.foremula-toast {
		.menu-img-src {
			background-position-y: 90%;
		}
	}
	.sambahl-dy,
	.carbonara,
	.oolong-rose {
		.menu-img-src {
			background-position-y: 20%;
		}
	}
	.section3 { background-image: url(../images/section3-item3.jpg); background-size: cover; background-position: 0px 0px; }
	.section3 .content-row { margin: 20px auto; }
	.mobile-item-3a { display: block; text-align: center; }
}

/* =Mobile 568
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 568px) {
	h3 { font-size: 2em; }
	h5 { font-size: 1.3em; }
	.menu-cup { width: 300px; }
	.mainMenu-inner ul { padding: 14px 0px; }
	.mainMenu-inner ul li { padding: 0px 80px; margin-left: -1px; margin-right: -1px; }
	.mainMenu-inner ul li a { font-size: 1em; letter-spacing: 1px; padding: 5px 15px; }
	.mainMenu-inner ul li:last-child a { padding-top: 5px; padding-bottom: 5px; border-radius: 0px 0px 8px 8px; }
	.mainMenu-inner ul li img { transform: scale(0.7); transform-origin: left; }
	.imgHover { top: 5px; left: 15px; }
	.desc-box1 { top: 165px; }
	.desc-box-text { max-width: 100%; }
	.coffee-slider { display: none; }
	.parallax-item-1, .parallax-item-2, .parallax-item-5b, .parallax-item-5c, .parallax-item-6a { transform: scale(0.5); }
	.parallax-item-1 { top: 50px; right: -50px; transform-origin: top right; }
	.parallax-item-2 { transform-origin: left; }
	.parallax-item-5b { right: -30px; transform-origin: top right; }
	.parallax-item-5c { right: -30px; transform-origin: top right; }
	.parallax-item-6a { display: none !important; }
	.section2 .content-row { width: 100%; }
	.section2 .menu-img { min-height: 0; height: 380px; }
	.section2 .menu-img-src { height: 300px; }
	.section2 .menu-img-desc h4 { font-size: 1.2em; }
	.section2 .menu-category-item a { margin: 10px 25px; }
	.section2 .menu-category-item h5 { font-size: 1em; }
	.section2 .menu-category-item svg { transform: scale(0.8); }
	.section2 .arrow a { width: 35px; height: 35px; }
	.section2 .arrow-left { top: 55%; }
	.section2 .arrow-right { top: 55%; left: auto; right: -40px; }
	.section2 {
		.back-basics h4,
		.carbonara h4,
		.ben-addict h4,
		.big-break h4,
		.egg-poached h4,
		.beef-chicken-sausages h4,
		.beef-bacon-turkey-ham h4 {
			font-size: 1.3em;
		}
		.ofishally-spicy p,
		.kambing p,
		.kimchi-ken p,
		.tgi-friday p {
			font-size: 1.2em;
		}
		.kambing img {
			top: -350%;
		}
	}
	.section3 .content-row { width: 90%; }
	.section5 {
		overflow: hidden;
		.testi-row { 
			a {
				width: 100%;
			}
		}
	}
	.section6 .bg { background-position-y: 0px !important;  }
	.section6 .content-box { padding-bottom: 160px; }
	form { width: 100%; }
	.form-label { text-align: left; }
	.form-group img { max-width: 100%; }
	.footer-container { display: flex; flex-direction: column; text-align: center; padding: 5px 0px; }
	.footer-forefront { text-align: center; order: 0; padding: 5px 0px; }
	.footer-copyright { order: 1; }
	footer { height: 160px; margin-top: -160px; }
	footer p { font-size: 0.9em; line-height: 1.5; margin-bottom: 10px; }
}

/* =Mobile 320
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 320px) {
	.section2 .menu-img { min-height: 45vh; }
	.section2 .menu-img-src { height: 65%; }
	.section2 .menu-img-desc h4 img { position: absolute; right: 0; bottom: 5px; }
	.section2 {
		.ofishally-spicy p,
		.kambing p,
		.kimchi-ken p,
		.tgi-friday p,
		.lean-and-green p {
			display: none;
		}
	}
	.QapTcha { width: 250px !important; }
	.QapTcha .bgSlider { width: 250px !important; background-size: contain !important; }
	.QapTcha .Slider { width: 28px !important; background-size: contain !important; background-position-y: center !important; }
}


/* =Mobile Landscape 568
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 568px) and (orientation : landscape) {
  /* ======================
	  Navigation
	 ====================== */
}

/* =Mobile Landscape 768
--------------------------------------------------------------------------------------------------------*/
@media only screen and (max-width: 768px) and (orientation : landscape) {
}


