form {
    width: 100%;
    margin: 0px auto;
    padding-top: 10px;

    @include on-desktop {
        width: 70%;
    }

    .row {
        margin-bottom: 10px;
    }
}
.form-label {
    text-align: right;
    label {
        font-family: $base-font-family;
        text-transform: uppercase;
    }
}
.form-control {
    font-family: $base-font-family;
    background: transparent;
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
}
select {
    border: none !important;
}
.has-select {
    position: relative;
    &:after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #ccc;
        display: block;
        left: 0;
        bottom: 0;
    }
}
textarea.form-control {
    background: white;
    border: 1px solid #ccc;
}
.form-check {
    label {
        font-family: $base-font-family;
    }
    input {
        margin-right: 10px;
    }
    a {
        color: $gold-color;
        position: relative;
    }
    a:before {
        content: "";
        width: 0;
        height: 1px;
        background-color: $gold-color;
        position: absolute;
        top: 100%;
        left: 0;
        transition: all ease 0.2s;
    }
    a:hover:before {
        width: 100%;
    }
}

input {
    .is-valid {
        border-bottom: 1px solid $green-color;
    }
    .is-invalid {
        border-bottom: 1px solid red;
    }
}

.booking-helper {
    padding-left: 20px;
    position: relative;
}

.icon-helper {
    max-width: 15px;
    position: absolute;
    top: 1px;
    left: 0;
}

.js-datepicker {
    .input-group-text {
        cursor: pointer;
    }
    .input-group-text img {
        max-width: 15px;
    }
}
