/**
 * @Landing desktop - mouse scroll UI
 */
.mouse {
    width: 25px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 25px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-out;
    
    .mouse__bullet {
        width: 5px;
        height: 5px;
        background: #fff;
        margin: auto auto;
        border-radius: 8px;
        position: relative;
    }
}

/**
 * @Landing mobile - finger swipe UI
 */
.finger {
    width: 25px;
    height: 50px;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 15vh;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease-out;

    .finger__arrow {
        width: 2px;
        height: 14px;
        background-color: white;
        position: relative;
        left: -3px;

        &::before,
        &::after {
            content: "";
            width: 8px;
            height: 2px;
            background-color: white;
            position: absolute;
            bottom: 2px;
        }
        &::before {
            right: -1px;
            transform: rotate(45deg);
        }
        &::after {
            left: -1px;
            transform: rotate(-45deg);
        }
    }

    .finger__img {
        max-width: 32px;
        position: relative;
        top: -10px;
    }
}

/**
 * @all - rounded corner button
 */
.btn--golden {
    color: $white-color;
    background: $gold-color;
    border-radius: 5px;
    display: inline-block;
    padding: 5px 15px 7px;
    position: relative;
    box-shadow: 0px 2px 4px rgba(0,0,0,0.3);
    transition: all ease 0.3s;

    &:hover {
        color: $white-color;
        background: darken($gold-color, 5%);
    }
}

/**
 * @all - modal
 */
.modal-bg {
    background-color: $bg-color;
}