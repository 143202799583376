/* ======================
	Animation
   ====================== */
/**
 *  Slider - Fade in Animation
 *  $num: number of images
 *  $fade: fade in duration
 *  $visible: visible duration
 *  Duration - Math: (fade-in + visible) × images = duration
 *  Delay - Math: (fade-in + visible) × (ordinal position − 1) = delay.
 *  Keyframe - Math: 100 ÷ animation duration = percentage for 1 second
 **/
@mixin fade($num:1, $fade:1, $visible:2) {
    $keyframe: 100 / (($fade + $visible) * $num);
    $percentage: unquote("%");
    
    @keyframes fade {
        0%   { opacity: 0; }
        #{($keyframe * $fade) + $percentage}    { opacity: 1; }
        #{$keyframe * ($fade + $visible) + $percentage}  { opacity: 1; }
        #{$keyframe * ($fade + $visible + $fade) + $percentage}  { opacity: 0; }
        100% { opacity: 0; }
    }

    animation-name: fade;
    animation-duration: (($fade + $visible) * $num) + s; 
    animation-iteration-count: infinite; 

    @for $i from 1 through $num {
        &:nth-child(#{$i}) {
            animation-delay: #{($fade + $visible) * ($i - 1)}s;
        }
    }
}

.slide--animation-fade {
    @include fade($num:2, $fade:2, $visible:4);
}


/**
 *  Mouse & Finger Indicator - Scrolling Animation
 **/
@keyframes scroll {
    0% { 
        opacity: 0;
        top: 20px;
    }
    33% {
        opacity: 1;
    }
    100% { 
        opacity: 0;
        top: 5px;
    }
}

.mouse__bullet--animation {
    animation: scroll 1.5s ease infinite;
}

@keyframes point {
    0% { 
        top: 0px;
    }
    100% { 
        top: 5px;
    }
}

.finger__arrow--animation {
    animation: point 1s ease-out infinite alternate;
}

@keyframes swipe {
    0% { 
        opacity: 0;
        top: 0px;
    }
    33% {
        opacity: 1;
        top: -10px;
    }
    66% { 
        opacity: 1;
    }
    100% { 
        opacity: 0;
    }
}

.finger__img--animation {
    animation: swipe 2s ease-out infinite;
}

