.title {
    text-align: center;
    padding: 50px 20px 20px;
    position: relative;
}

.boxbg {
    background: $dark-color;
}
.boxbg2 {
    background: rgba(44,42,39,0.7);
}

.divider {
    width: 100%;
    height: 140px;
    background: url(../images/pattern-box.jpg) 100% 100% repeat;
    position: relative;
    z-index: 1;
}

#map_google {
	width: 100%;
    min-height: 500px;
	height: 80vh;
}

.bg {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
}

.decor-cup {
    padding-top: 20px;
    margin-right: -50px;
    padding-left: 50px;
}

.item-reveal, .reveal {
    visibility: hidden;
}

.list-alpha {
    list-style: lower-alpha;
    padding-left: 30px;
}

.is-show {
    opacity: 1;
    visibility: visible;
}

.util-separator {
    display: inline-block;
    margin: 0px 10px;
}


/* ======================
	Desc Box
   ====================== */
.desc-box {
    position: absolute;
}
.desc-box1 {
    padding: 4px;
    top: 20%;
    right: 10%;
}
.desc-box-top, .desc-box-bottom {
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, lighten($gold-color,20%) 0%, $gold-color 75%);
    position: absolute;
}
.desc-box-left, .desc-box-right {
    width: 4px;
    height: 100%;
    position: absolute;
}
.desc-box-top {
    top: 0px;
    right: 0px;
}
.desc-box-bottom {
    bottom: 0px;
    left: 0px;
}
.desc-box-left {
    background: lighten($gold-color,20%);
    top: 0px;
    left: 0px;
}
.desc-box-right {
    background: $gold-color;
    bottom: 0px;
    right: 0px;
}
.desc-box-text {
    background: linear-gradient(45deg, rgba(232,210,179,0.7) 1%,rgba(189,128,101,1) 100%);
    background: $dark-color;
}


/* ======================
	Gradient
   ====================== */
.gd-border {
    position: relative;
    border-left: 4px solid lighten($gold-color,20%);;
    border-right: 4px solid $gold-color;
}
.gd-border:before {
    content: "";
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, lighten($gold-color,20%) 0%, $gold-color 75%);
    position: absolute;
    top: 0;
    left: 0;
}
.gd-border:after {
    content: "";
    width: 100%;
    height: 4px;
    background: linear-gradient(to right, lighten($gold-color,20%) 0%, $gold-color 75%);
    position: absolute;
    bottom: 0;
    left: 0;
}
.gd-bottom {
    background: linear-gradient(to top, rgba(200,198,196,1) 0%,rgba(200,198,196,0) 100%);
}

.gd-bg {
    background: linear-gradient(to top, rgba(189,128,101,0.3) 0%,rgba(232,210,179,0.3) 100%);
}

#scene{
    width: 100%;
    height: 500px;
    position: absolute;
}
.layer {
    position: absolute;
}





