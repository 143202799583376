html, body {
	width: 100%;
	height: 100%;
	font-size: 0.95rem;
}
.wrapper {
	width: 100%;
	min-height: 100%;
	height: auto;
	position: relative;
}
h1, h2, h3, h4, h5 {
	font-family: $base-font-family;
	letter-spacing: 2px;
	text-transform: uppercase;
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
}
h1 {
	font-size: 4em;
	display: flex;
}
h2 {
	font-size: 3em;
}
h3 {
	font-size: 2.5em;
}
h4 {
	font-size: 2em;
}
h5 {
	font-size: 1.5em;
}
b, strong {
	font-weight: 700;
}
p, ul {
	font-family: $base-font-family;
	font-size: 1em;
	color: $primary-color;
}
a {
	text-decoration: none;
}
a:hover, a:focus {
	text-decoration: none;
}
.overflowHide {
	overflow: hidden;
}



/* ======================
	Content
   ====================== */
.middle {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
}

.content-box {
    width: 80%;
    height: 80%;
    margin: 30px auto;
	position: relative;
	
	h3 {
		font-size: 2em;
	}
	p, ul {
		font-size: 1em;
		line-height: 1.6;
	}
}
.content-row {
	margin: 0px auto;
}

.social-row {
	padding: 20px 0px;
}
.social-row a {
	margin-right: 10px;
	display: inline-block;
}

.mobile-item {
	display: none;
}


/* ======================
	Footer
   ====================== */
footer {
	background: $dark-color;
	width: 100%;
	height: 65px;
	min-width: 100%;
	margin-top: -65px;
	padding: 15px 0px;
    clear: both;
    position: relative;

	p {
		font-size: 0.9em;
		color: #fff;
		margin-bottom: 0px;
		display: inline-block;
	}
	p a {
        color: white;
		position: relative;
		
		&:hover {
			color: $gold-color;
		}
    }
    p a:before {
        content: "";
        width: 0;
        height: 1px;
        background-color: $gold-color;
        position: absolute;
        top: 100%;
        left: 0;
        transition: all ease 0.2s;
    }
    p a:hover:before {
        width: 100%;
    }
	.social-row {
		display: inline-block;
		margin-left: 10px;
		padding: 5px 0px;
		vertical-align: middle;
	}
	.social-row img {
		width: 80%;
	}
	.social-row a {
		margin-right: 0px;
	}
}
.footer-container {
	width: 95%;
	margin:  0px auto;
}
.footer-forefront {
	text-align: right;

	a {
		display: inline-block;
		padding: 4px 0px;
	}
}




/* ======================
	Mo js
   ====================== */
.mo__item {
	position: absolute;
	top: 20%;
	left: 10%;
	z-index: 99;
}
.mo-wrap {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	overflow: hidden;
}
.mo-item {
	position: absolute;
	z-index: 1;
}
.mo-wrap .layer {
	width: 100%;
	height: 100%;
}
.mo-item-1 { top: 15%; left: 15%; }
.mo-item-2 { top: 22%; left: 22%; }
.mo-item-3 { top: 39%; left: 2%; }
.mo-item-4 { top: 50%; left: 4%; }
.mo-item-5 { top: 27%; left: 75%; }
.mo-item-6 { top: 35%; left: 81%; }
.mo-item-7 { top: 78%; left: 75%; }
.mo-item-8 { top: 87%; left: 85%; }
.mo-item-9 { top: 90%; left: 94%; }
.mo-item-10 { top: 72%; left: 15%; }
.mo-item-11 { top: 55%; left: 83%; }




