.coffee-slider {
    width: 40px;
    height: 338px;
    // height: 50vh;
    padding: 30px 0px;
    position: fixed;
    top: 50%;
    left: 30px;
    z-index: 10;
    transform: translateY(-50%);

    ul {
        width: 100%;
        height: 100%;
        text-align: center;
        padding-top: 26px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    ul:before {
        content: "";
        width: 1px;
        height: calc(100% - 26px);
        background: $primary-color;
        position: absolute;
        bottom: 0px;
        left: 50%;
    }
    li {
        width: 50%;
        height: 2px;
        background: $primary-color;
    }
}

.coffee-cup {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    transform: translateY(-50%);

    span {
        width: 22px;
        height: 0px;
        background: #ADA399;
        position: absolute;
        bottom: 5px;
        left: 0px;
        right: 0px;
        z-index: -1;
        margin: auto;
    }
}
