/*
Theme Name: Foremula
Author: the FFS team
Author URI: http://forefront.com.my/
Version: 1.0
License: for Forefront only
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: Forefront
*/

// Font weights
$light: 100;
$regular: 400;
$bold: 600;
$uppercase: uppercase;

// Base Font
$serif-font-family: 'Didot LT Std', serif;
$base-font-family: 'Roboto', sans-serif;;
$base-font-weight: $regular;

// Fonts Color
$primary-color: #000;
$secondary-color: #58534E;
$tertiary-color: #ADA399;
$bg-color: #ecebe4;
$coffee-color: #561e0e;
$grey-color: #b8b6b4;
$hover-color: #8f7655;
$dark-color: #2c2a27;
$gold-color: #ceb371;
$white-color: #fff;
$green-color: #28a745;

// Headings
$header-font-weight: $bold;


@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}

.box { @include border-radius(10px); }
