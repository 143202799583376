/* !FOCUSED IMAGES */
/*-----------------------------------------*/
.focuspoint {
	width: 100%;
	height: 100%;
	position: absolute;; /*Any position but static should work*/
	top: 0;
	overflow: hidden;
}
.focuspoint img {
	position: absolute;
	left: 0;
	top: 0;
/*	margin: 0;  */
	display: block;
	/* fill and maintain aspect ratio */
	width: auto; height: auto;
	min-width: 100%; min-height: 100%;
	max-height: none; max-width: none;
}