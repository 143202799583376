.menu-container {
    width:  100%;
    min-height: 100vh;
    height: 100%;
    position: absolute;
    top: 0px;
    display: flex;
    flex-wrap: wrap;
}
.menu-img {
    min-height: 100vh;
    height: 100%;
    display: inline-block;
    position: relative;
    padding: 0;
    overflow: hidden;
}
.menu-img-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: translateX(100%);
}
.menu-img-src {
    display: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center 100%;
    overflow: hidden;
    transform: translateX(-100%) scale(1.1);
}
.menu-img-src::before {
    content: "";
    display: none;

    @include on-tablet {
        width: 100%;
        height: 30%;
        background: linear-gradient(to top, rgba(black, 0.7) 0%, rgba(black, 0) 100%);
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}
.menu-img-src--vlign::before {
    display: none;
}
.menu-img-left {
    background-position: 0% 100%;
}
.menu-img-desc {
    width: 80%;
    text-align: left;
    margin: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    h4 {
        font-size: 1.5em;
    }
    h4, h5, p {
        color: $white-color
    }
    h4 img {
        vertical-align: text-top;
        display: inline-block;
        margin-top: -3px;
        margin-left: 15px;
    }
    h5 img {
        vertical-align: sub;
        margin-top: 0px;
        margin-bottom: 0px;
        margin-right: 5px;
    }
}
.menu-img-desc-vlign {
    width: 65%;
    text-align: center;
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    img {
        margin: 20px auto;
    }
}
.menu-col {
    min-height: 100vh;
    height: 100%;
    text-align: left;
    background: $dark-color;
    display: inline-block;
    position: relative;
    z-index: 3;
}
.menu-col-inner {
    padding-left: 20px;
    padding-right: 20px;
}
.menu-category {
    background-color: lighten($dark-color, 5%);
    display: flex;
    left: 0;
    right: 0;
    margin: auto;
    padding: 10px 0px 10px 20px;
    box-shadow: 0px 3px 8px rgba(0,0,0,0.3);
    overflow-x: scroll;
    z-index: 10;
    &:after {
        content: "\202F";
        width: 0px;
        height: 0px;
        position: relative;
        margin-left: 20px;
    }

    @include on-mobile-lg {
        justify-content: center;
    }
    
    @include on-tablet {
        background-color: transparent;
        flex-wrap: wrap;
        overflow-x: auto;
        padding: 0px;
        box-shadow: none;
        &:after {
            display: none;
        }
    }
}
.menu-category-item {
    text-align: center;
    position: relative;
    margin: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include on-mobile-lg {
        margin: 0px 10px;
    }

    @include on-tablet {
        margin: 15px 10px;
    }

    a {
        width: 50px;
        height: 50px;
        margin: 10px 15px;
        padding: 5px;
        border: 2px solid $gold-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: all ease 0.3s;
        &:hover {
            background-color: $gold-color;
        }
        &:hover svg path {
            fill: $white-color;
        }
        @include on-tablet {
            &:after {
                display: none;
            }
        }
    }
    a.active {
        background-color: $gold-color;
        &:after {
            content: "";
            background-color: $gold-color;
            width: 100%;
            height: 2px;
            position: absolute;
            left: 0;
            bottom: -10px;
            @include on-tablet { display: none; }
        }
        svg path {
            fill: $white-color;
        }
    }
    a.active + h5 {
        color: $gold-color;
    }
    svg path {
        fill: $gold-color;
        transition: all ease 0.3s;
    }
    h5 {
        font-size: 0.9em;
        letter-spacing: 1px;
        color: $white-color;
    }
}
.menu-list {
    width: 100%;
    min-height: 200px;
    position: relative;
    margin: 30px auto;
    display: flex;
    justify-content: center;

    ul {
        font-size: 1.1em;
        color: $white-color;
        text-transform: uppercase;
        letter-spacing: 2px;
    }
}
.menu-list-item {
    width: 100%;
    padding: 0px 10px;
    position: absolute;
    top: 0;
    left: 0;
    h5 {
        font-size: 1.1em;
        background-color: $secondary-color;
        text-transform: none;
        display: inline-block;
        padding: 5px 30px 5px 10px;
    }
    li {
        margin-bottom: 5px;
        opacity: 0;
    }
    a {
        color: $white-color;
        cursor: pointer;
        padding: 3px 5px;
        position: relative;
        transition: all ease 0.3s;
        &:before {
            content: "";
            width: 100%;
            height: 0px;
            background: $gold-color;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            transition: all 0.2s ease;
        }
        &:hover {
            color: $white-color;
        }
        &:hover::before {
            height: 100%;
        }
    }
    a.active {
        color: $white-color;
        &::before {
            height: 100%;
        }
    }
}
.menu-list-item.active {
    position: relative;
    z-index: 1;
    li {
        opacity: 1;
    }
}
.menu-list-row {
    width:  100%;
    white-space: nowrap;
    overflow: hidden;
}
.menu-list-col {
    float: none;
    display: inline-block;
    vertical-align: top;
}
.menu__subcategory {
    letter-spacing: 0px !important;
    white-space: nowrap;
    margin-bottom: 30px;
    &:after {
        content: "";
        flex: auto;
        flex-grow: 0.7;
    }
}
.menu__subcategory li {
    font-size: 0.9em;
    text-transform: none;
    display: inline-block;
    margin-bottom: 5px;

    a:before {
        border-radius: 5px;
        z-index: 0;
    }
}
.menu__subcategory li a {
    border: 1px solid $secondary-color;
    border-radius: 5px;
    display: inline-block;
    margin: 0px 3px;
    padding: 5px 10px;

    span {
        position: relative;
        z-index: 1;
    }
}
.menu__sublist {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
}
.menu__sublist.active {
    position: relative;
}
.menu__singlelist {
    opacity: 0;
    visibility: hidden;
}
.menu-list-item.active .menu__sublist.active,
.menu-list-item.active .menu__singlelist.active {
    opacity: 1;
    visibility: visible;
}
.menu-indicate {
    position: absolute;
    top: 50%;
    right: 0;
    margin: auto;
    transform: translateY(-50%);
    z-index: 5;
}
.indicate-left {
    width: 0; 
    height: 0; 
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent; 
    border-right: 30px solid $dark-color;
    &:after {
        content: "";
        width: 0; 
        height: 0; 
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent; 
        border-right: 15px solid $gold-color;
        position: absolute;
        top: -10px;
        right: -30px;
    }
}

// On desktop food bg position
.sambahl-dy,
.carbonara,
.oolong-rose {
    .menu-img-src {
        background-position-y: 0%;
    }
}