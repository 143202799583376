@font-face {
	font-family: 'gobold';
	src: url('../fonts/gobold-webfont.eot');
	src: url('../fonts/gobold-webfont.eot?#iefix') format('embedded-opentype'),
		url('../fonts/gobold-webfont.woff') format('woff'),
		url('../fonts/gobold-webfont.ttf') format('truetype'),
		url('../fonts/gobold-webfont.svg#goboldregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Didot LT Std';
	src: url('../fonts/DidotLTStd-Bold.eot');
	src: url('../fonts/DidotLTStd-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/DidotLTStd-Bold.woff') format('woff'),
		url('../fonts/DidotLTStd-Bold.woff2') format('woff2'),
		url('../fonts/DidotLTStd-Bold.ttf') format('truetype'),
		url('../fonts/DidotLTStd-Bold.svg#DidotLTStd-Bold') format('svg');
		font-weight: bold;
	font-style: normal;
}