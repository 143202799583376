/* ======================
	Menu Button
   ====================== */
.menuBtn-container {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	clip: rect(0, auto, auto, 0);
	pointer-events: none;
}
.menuBtn,
.menuBtn-static {
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	text-decoration: none;
	color: $primary-color;
	border: 1px solid $primary-color;
	position: fixed;
	top: 30px;
	right: 30px;
	transition: all .2s ease-out;
	z-index: 35;

	i {
		width: 18px;
		height: 2px;
		background: $primary-color;
		display: inline-block;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		transition: all .2s ease-out;
	}
	i::before,
	i::after {
		content:'';
		width: 18px;
		height: 2px;
		background: $primary-color;
		position: absolute;
		left:0;
		transition: all .2s ease-out;
	}
	i::before {
		top: -6px;
	}
	i::after {
		bottom: -6px;
	}

	&.is-white {
		border-color: white;
	}
	&.is-white i,
	&.is-white i:before,
	&.is-white i:after {
		background: white;
	}
}
.menuBtn:hover {
	background: $hover-color;
	border: 1px solid $hover-color;

	i, 
	i::before, 
	i::after {
		background: white;
	}
}
.menuBtn.active {
	border: 1px solid $hover-color;

	i {
		background: none;
	}
	i::before {
		background: $hover-color;
		top:0;
		transform: rotateZ(45deg);
	}
	i::after {
		background: $hover-color;
		bottom:0;
		transform: rotateZ(-45deg);
	}
}
.menuBtn.active:hover {
	i::before, 
	i::after {
		background: white;
	}
}




/* ======================
	Navigation
   ====================== */
nav {
	width: 100%;
	height: 100%;
	min-height: 100vh;
	display: block;
	position: fixed;
	top: 0px;
	left: 0px;
	opacity: 0;
	visibility: hidden;
	z-index: 30;
	will-change: opacity, visibility;
	transition: all ease-out 0.3s;

	.bg {
		background-color: $bg-color;
	}
	.bg-inner {
		width: 100%;
		height: 100%;
		background-size: contain;
		background-position: left bottom;
		background-repeat: no-repeat;
		position: fixed;
		top: 0px;
		left: 0px;
	}
	.bg-left {
		opacity: 0;
		transform-origin: 0% 100%;
		transform: translateX(-25%) rotate(35deg);
		transition: all ease 0.5s;
	}
	.bg-right {
		opacity: 0;
		transform-origin: 100% 100%;
		transform: translateX(25%) rotate(-35deg);
		transition: all ease 0.5s;
	}
	.bg-left1 { background-image: url(../images/menu-leftbg1.png); }
	.bg-left2 { background-image: url(../images/menu-leftbg2.png); transition-delay: 0.05s; }
	.bg-left3 { background-image: url(../images/menu-leftbg3.png); transition-delay: 0.1s }
	.bg-right1 { background-image: url(../images/menu-rightbg1.png); }
	.bg-right2 { background-image: url(../images/menu-rightbg2.png); transition-delay: 0.05s; }
	.bg-right3 { background-image: url(../images/menu-rightbg3.png); transition-delay: 0.1s }
}
nav.active {
	opacity: 1;
	visibility: visible;
}
nav.active .bg-left,
nav.active .bg-right {
	opacity: 1;
	transform: translateX(0%) rotate(0deg);
}
nav.active + .wrapper .menuBtn-container {
	opacity: 0;
	visibility: hidden;
}
.mainMenu {
	max-height: 0px;
	font-family: $base-font-family;
	font-weight: normal;
	text-align: center;
	display: block;
	overflow: hidden;
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}
.mainMenu.active {
	max-height: 500px;
}
.mainMenu-inner {
	text-align: center;
	display: inline-block;
	position: relative;

	ul {
		text-align: left;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		margin: auto;
		padding: 18px 0px;
	}
	ul li {
		padding: 0px 126px;
	}
	ul li a {
		font-family: $serif-font-family;
		font-weight: 700;
		font-size: 1.5em;
		text-transform: uppercase;
		letter-spacing: 2px;
		white-space: nowrap;
		color: $dark-color;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		margin-right: 1px;
		padding: 10px 25px;
		transition: all 0.3s ease;
	}
	ul li:last-child a {
		padding-top: 8px;
		padding-bottom: 9px;
		border-radius: 0px 0px 15px 15px;
	}
}
.menu-cup {
	position: relative;
	opacity: 0.5;
}
nav ul li a:hover {
	color: $white-color;
	background: #654333;
}
nav ul li a:hover .imgBtn {
	opacity: 0;
}
nav ul li a:hover .imgHover {
	opacity: 1;
}

.imgBtn {
	opacity: 1;
	transition: all ease 0.3;
}
.imgHover {
	position: absolute;
	top: 16px;
	left: 35px;
	opacity: 0;
	transition: all ease 0.3;
}




/* ======================
	Cross Button
   ====================== */
.crossBtn {
	display: block;
	width: 40px;
	height: 40px;
	cursor: pointer;
	text-decoration: none;
	color: #1e3a6d;
	position: absolute;
	top: 20px;
	right: 20px;
	z-index: 15;
}
.crossBtn i {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 8px;
	margin: auto;
	display: inline-block;
	width: 18px;
	height: 2px;
	transition: all .2s ease-out;
}
.crossBtn i::before, .crossBtn i::after {
	content:'';
	width: 18px;
	height: 2px;
	background: #fff;
	position: absolute;
	left:0;
	transition: all .2s ease-out;
}
.crossBtn i::before {
  top:0;
	transform: rotateZ(45deg);
}
.crossBtn i::after {
  bottom:0;
	transform: rotateZ(-45deg);
}

/* ======================
	Logo
   ====================== */
.logo {
	position: absolute;
	top: 30px;
	left: 50px;
	z-index: 50;
	&.active {
		position: fixed;
	}
}
.logo svg, .logo img {
	max-width: 100%;
	transition: all 0.3s ease;
}
.logo svg {
	width: 146px;
}
.logo.active svg {
	fill: $hover-color;
}

