.section {
    position: relative;
}
.fullscreen {
    width: 100%;
    min-height: 100vh;
    height: 100%;
}
.section1, .section2, .section3, .section5, .section6, .section7 {
    @extend .fullscreen;
}
.section-title {
    font-family: $serif-font-family;
    position: relative;
    display: inline-block;
    margin-top: 0px;

    &:after {
        content: "";
        width: 80%;
        height: 1px;
        background: #333;
        margin: auto;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -5px;
    }
}
.title-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    clip: rect(0, auto, auto, 0);
    pointer-events: none;
}
.section-bg {
    .bg {
        background: $bg-color url(../images/section2-slide1.png) 0 0 no-repeat fixed;
        background-size: cover;
        background-position: center;
        z-index: -2;
    }
}

.section1 {
    .desc-box-text {
        font-family: $serif-font-family;
        font-size: 3em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 1.5em;
        letter-spacing: 0px;
        color: white;
        padding: 25px 30px;
        @include on-tablet {
            padding: 50px 60px;
        }
    }
    .desc-box-text span {
        display: inline-block;
    }
}

.section2 {
    min-height: 768px;
    height: auto;
    text-align: center;
    
    @include on-tablet {
        height: 100vh;
    }
    .bg {
        background: url(../images/section2-slide1.png) 0 0 no-repeat fixed;
        background-size: cover;
    }
    .title {
        display: inline-block;
        padding-top: 50px;
        z-index: 5;
    }
    .section-title {
        color: $grey-color;
        &:after {
            background: $grey-color;
        }
    }
    .title-static {
        position: absolute;
        left: 0;
        right: 0;
        margin-right: -100%;
        display: none;

        @include on-tablet {
            display: inline-block;
        }

        .section-title {
            color: $dark-color;
            text-shadow: 0px 0px 3px $grey-color;
            &:after {
                background: $dark-color;
            }
        }
    }
    .content-row {
        width: 60%;
    }
    .content-box {
        min-height: 60vh;
        text-align: center;
    }
}


.section3 {
    background: url(../images/section3-item2.png) 110% 100% no-repeat;
    .title {
        padding-top: 50px;
    }
    .content-gd{
        width: 100%;
        height: 30%;
        min-height: 30vh;
        position: absolute;
        bottom: 0;
    }
    .content-row {
        width: 70%;
        display: block;
        margin: 20px 0px;
    }
    .content-box {
        margin-top: 5%;
    }
    h3 small {
        color: $primary-color;
        display: block;
    }
}

.section4 {
    .title {
        padding-top: 20px;
    }
    .section-title {
        color: $grey-color;
        &:after {
            background: $grey-color;
        }
    }
    .social-row {
        position: relative;
    }
}

.section5 {
    p, ul {
        font-size: 1.1em;
        color: white;
    }
    .content-box {
        min-height: 55vh;
        margin: 10px auto;
        text-align: center;
    }
    .content-row {
        width: 60%;
        text-align: left;
        position: relative;
        padding: 30px 0px;
        z-index: 1;

        @include on-tablet {
            width: 50%;
        }
    }
    .content-location li {
        margin-bottom: 15px;
    }
    .content-location span {
        margin-left: -40px;
        padding-right: 21px;
        display: inline-block;
    }
    .content-location a {
        display: inline-block;
        margin-right: 10px;
    }
    .social-row {
        position: relative;
        p, ul {
            color: $primary-color;
        }
    }
    .testi-row {
        width: 80%;
        margin: 0px auto 20px auto;
        display: flex;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        -webkit-justify-content: space-around;
        justify-content: space-around;
        img {
            filter: grayscale(100%);
            transition: all ease 0.3s;
        }
        img:hover {
            filter: grayscale(0%);
        }
        a {
            margin: 10px;
        }
    }
}

.section6 {
    .bg {
        background: url(../images/section6-slide1.jpg) 0 0 no-repeat fixed;
    }
    .content-box {
        width: 90%;
        padding-bottom: 65px;

        @include on-tablet {
            width: 80%;
        }
    }
}

.section7 {
    .bg {
        background: $bg-color url(../images/section2-slide1.png) 0 0 no-repeat fixed;
        background-size: cover;
        z-index: -2;
    }
    .parallax-item-2 {
        z-index: -1;
    }
    .middle {
        top: 40%;
    }
    .title {
        padding-top: 50px;
    }
    .content-row {
        width: 50%;
    }
    .content-box {
        min-height: 30vh;
        text-align: center;
    }    
}

