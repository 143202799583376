/* ======================
	Flex
   ====================== */
.flex {
    display: flex;
}
.flex-column {
    flex-direction: column;
}
.flex-align-center {
    align-items: center;
}
.flex-justify-center {
    justify-content: center;
}
.flex-justify-space {
    justify-content: space-around;
}