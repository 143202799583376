#loader-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    // background: rgb(223,189,150);
    background: $bg-color;
    z-index: 1000;
}

// Loaded
.loaded .coffee-item {
    opacity: 0;
    transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.5s 0.5s ease-out;
    transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */
}


// percentage
.percentage{
  font-size : 15px;
  font-weight: 300;
  color: #fff;
  height: 30px;
  max-width: 500px;
  text-align:center;
  line-height: 30px;
  position: absolute; 
  top: 0; 
  bottom: 0; 
  left: 0; 
  right: 0;
  z-index: 100;
  margin : auto;

  display: none;
}


// Coffee
.coffee-item {
    width: 150px;
    height: auto;
    padding: 2em 1em;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.coffee-cup-container {
    position: relative;
    display: block;
    padding-top: 66.67%;
}
.coffee-cup-body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cup {
        display: block;
        position: relative;
        width: 66.67%;
        height: 100%;
        border-radius: 0 0 20px 20px;
        overflow: hidden;
    }
    .cup .cup-body {
        display: block;
        position: absolute;
        top: 0;
        z-index: 5;
        width: 100%;
        height: 100%;
        border-radius: 0 0 20px 20px;
        border: 5px solid $coffee-color;
        // border-image: linear-gradient(to top, rgba(196,151,99,1), rgba(215, 181, 132, 1)) 1 100%;
        border-top-width: 0;
    }
    /*.cup .cup-body:before {
        content: '';
        display: block;
        position: absolute;
        right: 15%;
        width: 45%;
        height: 100%;
        border-radius: 0 0 12px 12px;
        background: -webkit-linear-gradient(right, rgba(255,255,255,0.2) 30%, rgba(255,255,255,0.4) 30%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0.2) 50%);
        background: linear-gradient(to left, rgba(255,255,255,0.2) 30%, rgba(255,255,255,0.4) 30%, rgba(255,255,255,0.4) 50%, rgba(255,255,255,0.2) 50%);
    }*/
    .cup .coffee {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 0%;
        max-height: 98%;
    }
    .ear {
        position: relative;
        display: block;
        overflow: hidden;
        width: 16.67%;
        height: 50%;
    }
    .ear:after {
        content: '';
        display: block;
        position: absolute;
        right: 5px;
        width: calc( 150% - 10px );
        height: calc( 100% - 10px );
        border: 5px solid $coffee-color;
        border-radius: 25%;
        z-index: -1;
    }
}
.coffee-plate-container {
    width: 100%;
    margin-top: 0.5em;
    padding: 0 25%;

    .coffee-plate {
        display: block;
        width: 100%;
        height: 5px;
        border-radius: 2.5px;
        background-color: $coffee-color;
    }
}
.espresso .coffee {
    background: -webkit-linear-gradient(bottom, $coffee-color 30%, transparent 30%);
    background: linear-gradient(to top, $coffee-color 30%, transparent 30%);
    background: #3a1e16;
}
.coffee-cup-logo {
    opacity: 0;
    visibility: hidden;

    img {
        max-width: 100%;
        position: absolute;
        top: 100%;
        left: 3%;
    }
    
}


// Steam
.steam {
  width: 50px;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
}
.smoke1, .smoke2, .smoke3 {
  width: 6px;
  height: 30px;
  float: left;
  margin-right: 6px;
  margin-left: 4px;
  border-radius: 0 100%;
  transform: rotate(10deg);
}
.smoke1::before,
.smoke1::after,
.smoke2::before,
.smoke2::after,
.smoke3::before,
.smoke3::after {
  content: "";
  background: #8b6139;
  position: absolute;
  animation: hot 2.9s infinite reverse ease-in-out;
  border-radius: 0 100%;
}
.smoke2 {
  height: 15px;
}
.smoke2::before,
.smoke2::after {
  animation: hot 2.6s infinite reverse ease-in-out;
  margin-top: 5px;
}
.smoke3::before,
.smoke3::after {
  animation: hot 2.9s infinite 0.5s reverse ease-in-out;
}
@keyframes hot {
  0% {
    top: 0;
    height: 30px;
    width: 6px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 29px;
    height: 15px;
    width: 6px;
    opacity: 0;
  }
}




