/**
 * Parallax initial position
 */
.parallax-item-1 {
	width: 400px;
	height: 400px;
    background: url(../images/section2-item1.png) 50% 0 no-repeat;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    top: 0;
    right: 0;
}
.parallax-item-2 {
	width: 500px;
	height: 500px;
    background: url(../images/section2-item2.png) 50% 0 no-repeat;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    bottom: 0;
    left: -100px;
}
.parallax-item-3a {
	width: 300px;
	height: 300px;
    background: url(../images/section3-item1.png) 50% 0 no-repeat;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    top: 70%;
    right: 50px;
}

.parallax-item-5a {
	width: 400px;
	height: 400px;
    background: url(../images/section5-item1.png) 50% 0 no-repeat;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    top: 30%;
    left: -50px;
}
.parallax-item-5b {
	width: 200px;
	height: 250px;
	background: url(../images/section5-item2.png) 50% 0 no-repeat;
	background-size: contain;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    top: 42%;
	right: 10%;
	@include on-tablet {
		width: 400px;
		height: 500px;
	}
}
.parallax-item-5c {
	width: 150px;
	height: 240px;
	background: url(../images/section5-item3.png) 50% 0 no-repeat;
	background-size: contain;
	margin: 0 auto;
	padding: 0;
    position: absolute;
    top: 80%;
	right: 3%;
	@include on-tablet {
		width: 250px;
		height: 400px;
	}
}

.parallax-item-6a {
	width: 400px;
	height: 650px;
    background: url(../images/section6-item1.png) 50% 0 no-repeat;
	margin: 0 auto;
	padding: 0;
	position: absolute;
	top: 40%;
	opacity: 0.2;
	@include on-desktop {
		opacity: 1;
	}
}
